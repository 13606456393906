import React, { useEffect, useState, useRef } from "react";
import "./admindashboard.scss";
import { Link } from "react-router-dom";
import axios from "axios";
export const AdminDashboard = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState([])
  useEffect(()=>{
    const fetchData = async () => {
      const resp = await axios.get(
        url + "/admin/getdashboarddata",
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status===200){
        setData(resp.data.totinr);
      }
      
    };
    fetchData();
  },[])
  return (
    <div className="admindashboard">
      <div className="topbar">
        <div className="grid">
          <div className="col-6 md:col-6 lg:col-2 cont">
            <div className="title">Wallet Request</div>
            <Link to="/admin/walletrequest">{data}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
