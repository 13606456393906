import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { ethers } from "ethers";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import "./newinvest.scss"
export const NewInvest = () => {
    const url = process.env.REACT_APP_HOST_ADDR;
  const toast = useRef(null);
  const actuser = localStorage.getItem("mxactiveuser")
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const validationSchema = Yup.object({
    trandate: Yup.date("Invalid Date.").required("Field cant not be blank."),
    topupval: Yup.number("Invalid Topup Value").required("Top-Up Id cant not be blank."),
  });
  const formik = useFormik({
    initialValues: {
        actuser : actuser,
      trandate: "",
      topupval: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //setButtonDisabled(true);
      const resp = await axios.post(url + "/user/checktopup", {
        values
      });
      console.log(resp.data)
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className='newinvest'>
        <div className="grid">
            <div className="col-6 md:col-6 lg:col-6 left">
                <Card title="New Invest">
                <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div>
                    <div>Date</div>
                    <InputText
                      type="date"
                      name="trandate"
                      className="p-inputtext-sm"
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.trandate}
                    />
                    {getFormErrorMessage("trandate")}
                  </div>
                  <div>
                    <div>Topup Value</div>
                    <InputText
                      type="number"
                      name="topupval"
                      className="p-inputtext-sm"
                      placeholder="Top Up Value"
                      onChange={formik.handleChange}
                      value={formik.values.topupval}
                    />
                    {getFormErrorMessage("topupval")}
                  </div>
                  {isButtonDisabled === false ? (
                    <Button type="submit">Submit</Button>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      icon="pi pi-spin pi-spinner"
                    >
                      {" "}
                      Submitting..
                    </Button>
                  )}
                </div>
              </Form>
            </FormikProvider>
                </Card>
            </div>
            <div className="col-6 md:col-6 lg:col-6 right">
                Sharma
            </div>
        </div>
    </div>
  )
}
