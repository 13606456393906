import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./style.scss";
import { DarkModeContext } from "./context/darkModeContext";
import React, { useContext } from "react";
import { HomeAppbar } from "./components/homecomponent/homappbar/HomeAppbar";
import { HomeFooter } from "./components/homecomponent/homefooter/HomeFooter";
import { Home } from "./pages/home/Home";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import { Login } from "./pages/home/Login";
import { SignUp } from "./pages/home/SignUp";
import { Userappbar } from "./components/usercomponent/userappbar/Userappbar";
import { UserFooter } from "./components/usercomponent/userfooter/UserFooter";
import { UserDashBoard } from "./pages/user/dashboard/UserDashBoard";
import { MailVerify } from "./pages/user/mailverify/MailVerify";
import { KycUpdate } from "./pages/user/kycupdate/KycUpdate";
import { NewInvest } from "./pages/user/newinvest/NewInvest";
import { MyBucket } from "./pages/user/mybucket/MyBucket";
import { AdminLogin } from "./pages/home/AdminLogin";
import { AdminDashboard } from "./pages/admin/admindashboard/AdminDashboard";
import { AdminFooter } from "./components/admincomponent/AdminFooter";
import { RequestTopUpInr } from "./pages/user/requesttopupinr/RequestTopUpInr";
import { WalletRequest } from "./pages/admin/walletrequest/WalletRequest";
import { CreditUser } from "./pages/admin/credituser/CreditUser";
import { IncomeDetails } from "./pages/user/incomedetails/IncomeDetails";
import { MyLeves } from "./pages/user/mylevels/MyLeves";
import { Settings } from "./pages/user/settings/Settings";
import { P2PTransfer } from "./pages/user/p2ptransfer/P2PTransfer";
import { ManageWeekly } from "./pages/user/manageweekly/ManageWeekly";
import { ManageMonthly } from "./pages/user/managemonthly/ManageMonthly";
import { MyAccount } from "./pages/user/myaccount/MyAccount";
import { UserProfile } from "./pages/user/userprofile/UserProfile";
import { CryptoDeposit } from "./pages/user/cryptodeposit/CryptoDeposit";
import { LiveBalanceReq } from "./pages/user/livebalancereq/LiveBalanceReq";
import { AdminAppBar } from "./components/admincomponent/AdminAppBar";
import { AllUsers } from "./pages/admin/allusers/AllUsers";
import { UsersProfile } from "./pages/admin/usersprofile/UsersProfile";
import { EditProfile } from "./pages/admin/editprofile/EditProfile";
import { Downline } from "./pages/user/downline/Downline";
import { ForgetPassword } from "./pages/home/forgetpassword/ForgetPassword";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const HomeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <HomeAppbar />
        <Outlet />
        <HomeFooter />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <Userappbar />
        <Outlet />
        <UserFooter />
      </div>
    );
  };
  const AdminLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminAppBar />
        <Outlet />
        <AdminFooter />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/login", element: <Login /> },
        { path: "/forgetpassword", element: <ForgetPassword /> },
        { path: "/adminlogin", element: <AdminLogin /> },
        { path: "/signup/:spid", element: <SignUp /> },
      ]
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user/", element: <UserDashBoard /> },
        { path: "/user/mailverify", element: <MailVerify /> },
        { path: "/user/kycupdate", element: <KycUpdate /> },
        { path: "/user/mybucket", element: <MyBucket /> },
        { path: "/user/mylevels", element: <MyLeves /> },
        { path: "/user/requesttopupinr", element: <RequestTopUpInr /> },
        { path: "/user/incomedetails", element: <IncomeDetails /> },
        { path: "/user/settings", element: <Settings /> },
        { path: "/user/p2ptransfer", element: <P2PTransfer /> },
        { path: "/user/manageweekly", element: <ManageWeekly /> },
        { path: "/user/managemonthly", element: <ManageMonthly /> },      
        { path: "/user/myaccount", element: <MyAccount /> },      
        { path: "/user/userprofile", element: <UserProfile /> },      
        { path: "/user/cryptodeposit", element: <CryptoDeposit /> },      
        { path: "/user/livebalancereq", element: <LiveBalanceReq /> },      
        { path: "/user/downline", element: <Downline /> },      
    ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "/admin/", element: <AdminDashboard /> },
        { path: "/admin/walletrequest", element: <WalletRequest /> },
        { path: "/admin/credituser", element: <CreditUser /> },      
        { path: "/admin/allusers", element: <AllUsers /> },      
        { path: "/admin/usersprofile", element: <UsersProfile /> },      
        { path: "/admin/editprofile/:email", element: <EditProfile /> },      
    ],
    },
  ])
  return (
    <RouterProvider router={router} />
);
}

export default App;
